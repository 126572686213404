<template>
  <c-box
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
  >
    <Stepper
      margin-top="16px"
      :margin-bottom="['12px', '20px']"
      :steps="[
        {
          label: 'Informasi Pribadi',
        },
        {
          label: 'Periksa',
        },
        {
          label: 'Pembayaran',
          isCurrentStep: true,
          isError: isTransactionFailed,
        },
      ]"
    />
    <c-box
      v-if="!isLoading && transactionStatus === 'failed'"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <BaseText
        size-mobile="18px"
        size-desktop="28px"
        text-align="center"
      >
        Pembayaran Gagal!
      </BaseText>

      <c-box
        :max-height="['196px','226px']"
        height="100%"
        :max-width="['173px','200px']"
        width="100%"
        margin-top="20px"
      >
        <c-image
          :src="require('@/assets/images/illustration-6-raw.png')"
          height="100%"
          width="100%"
        />
      </c-box>

      <BaseText
        size-mobile="14px-2"
        size-desktop="16px"
        margin-top="20px"
        text-align="center"
      >
        Ups! pembayaran kamu gagal, pesanan otomatis dibatalkan, beli ulang pesananmu ya.
      </BaseText>
        
      <c-box
        w="100%"
        display="flex"
        justify-content="center"
        margin-top="20px"
      >
        <BaseButton
          size="large"
          variant="contained"
          width="100%"
          border-radius="1000px"
          :max-width="['269px', '500px']"
          @click="handleNext('visitTransactionHistory')"
        >
          Cek Riwayat Transaksi
        </BaseButton>
      </c-box>
    </c-box>

    <c-box
      v-else-if="!isLoading && transactionStatus === 'done'"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <BaseText
        size-mobile="18px"
        size-desktop="28px"
        text-align="center"
      >
        Pembayaran Berhasil!
      </BaseText>

      <c-box
        :max-height="['196px','226px']"
        height="100%"
        :max-width="['173px','200px']"
        width="100%"
        margin-top="20px"
      >
        <inline-svg
          :src="require('@/assets/images/image-payment-success.svg')"
          height="100%"
          width="100%"
        />
      </c-box>

      <c-box
        :background-color="['#FFF', '#FFF']"
        :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
        :border-radius="['8px', '16px']"
        :padding="['12px', '30px']"
        margin-top="20px"
      >
        <BaseText
          size-mobile="16px"
          size-desktop="20px"
          color="primary.400"
        >
          Hi {{ fullName }},
        </BaseText>
        <BaseDivider />
        <BaseText
          size-mobile="14px"
          size-desktop="16px-2"
        >
          Pembayaran untuk pesanan dengan nomor {{ orderId }} sudah kami terima, berikut detail pembayaranmu:
        </BaseText>

        <c-box
          id="table"
          as="table"
          :margin-top="['12px', '25px']"
          :border-spacing="['160px']"
        >
          <c-box as="tr">
            <c-box as="td">
              <BaseText
                size-mobile="12px"
                size-desktop="16px"
                white-space="nowrap"
                margin-right="16px"
              >
                Nama Program
              </BaseText>
            </c-box>
            <c-box as="td">
              <BaseText
                size-mobile="12px"
                size-desktop="18px"
              >
                : {{ programName }}
              </BaseText>
            </c-box>
          </c-box>

          <c-box as="tr">
            <c-box as="td">
              <BaseText
                size-mobile="12px"
                size-desktop="16px"
                white-space="nowrap"
                margin-right="16px"
              >
                Biaya
              </BaseText>
            </c-box>
            <c-box
              as="td"
              width="100%"
            >
              <BaseText
                size-mobile="12px"
                size-desktop="18px"
              >
                : {{ price }}
              </BaseText>
            </c-box>
          </c-box>

          <c-box as="tr">
            <c-box as="td">
              <BaseText
                size-mobile="12px"
                size-desktop="16px"
                white-space="nowrap"
                margin-right="16px"
              >
                Metode Pembayaran
              </BaseText>
            </c-box>
            <c-box as="td">
              <BaseText
                size-mobile="12px"
                size-desktop="18px"
              >
                : {{ paymentMethod }}
              </BaseText>
            </c-box>
          </c-box>

          <c-box as="tr">
            <c-box as="td">
              <BaseText
                size-mobile="12px"
                size-desktop="16px"
                white-space="nowrap"
                margin-right="16px"
              >
                Tanggal
              </BaseText>
            </c-box>
            <c-box as="td">
              <BaseText
                size-mobile="12px"
                size-desktop="18px"
              >
                : {{ orderDate }}
              </BaseText>
            </c-box>
          </c-box>
        </c-box>
      </c-box>

      <BaseText
        size-mobile="14px"
        size-desktop="20px"
        color="primary.400"
        margin-top="20px"
      >
        {{ isChooseNutritionist
          ? 'Silahkan melakukan pemilihan ahli gizi'
          : 'Durasi program akan otomatis diperpanjang dengan program yang sebelumnya'
        }}
      </BaseText>

      <c-box
        w="100%"
        display="flex"
        justify-content="center"
        margin-top="20px"
      >
        <BaseButton
          size="large"
          variant="contained"
          width="100%"
          border-radius="1000px"
          :max-width="['269px', '500px']"
          @click="handleNext"
        >
          {{ isChooseNutritionist
            ? 'Pilih Ahli Gizi'
            : 'Cek Program'
          }}
        </BaseButton>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import Stepper from '@/components/elements/stepper.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import { mapActions, mapGetters } from 'vuex'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import { formatCurrency } from '@/utils/format-currency'
import { formatDateV2 } from '@/utils/format-date'

export default {
  name: 'Buy4Page',
  components: {
    BaseDivider,
    BaseButton,
    BaseText,
    Stepper,
  },
  data() {
    return {
      isLoading: false,
      transactionStatus: null,
      orderId: '...',
      programName: '...',
      price: '...',
      paymentMethod: '...',
      orderDate: '...',
      isTransactionFailed: false,
      isChooseNutritionist: false,
      latestProgramId: '',
    }
  },
  computed: {
    ...mapGetters({
      fullName: 'auth/fullName',
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      getTransaction: 'checkout/getTransaction',
      listRiwayatPrograms: 'profileProgram/listHistoryPrograms',
    }),
    handleNext(type = '') {
      if (this.isChooseNutritionist) {
        this.$router.push({ name: 'client.nutritionists' })
        return
      }

      if (type === 'visitTransactionHistory') {
        this.$router.push({ name: 'client.profile.historyTransaction' })
        return
      }
      if (this.isAuthenticated) {
        this.$router.push({ name: 'client.profile.programDetail', params: { programId: this.latestProgramId } })
        return
      }
      this.$router.push({ name: 'auth' })
    },
    async init() {
      try {
        this.isLoading = true
        const transactionId = this.$route?.query?.transaction_id

        if (!transactionId) {
          throw new Error('ERR_NO_TRANSACTION_ID')
        }

        const res = await this.getTransaction(transactionId)

        if (res.data?.status === 'pending') {
          throw new Error('ERR_TRANSACTION_PENDING')
        }

        if (res.data?.status === 'failed') {
          this.isTransactionFailed = true
        }

        this.transactionStatus = res.data?.status || '-'
        this.orderId = res.data?.invoiceNumber || '-'
        this.programName = `${res.data?.productName} - ${res.data?.productServiceName} - ${res.data?.duration} hari`
        this.price = res.data.subTotal ? formatCurrency(res.data.subTotal) : '-'
        this.paymentMethod = res.data.paymentMethodName || '-'
        this.orderDate = res.data?.createdAt ? formatDateV2(res.data?.createdAt) : '-'

        if (res.data?.status !== 'failed') {
          const latestProgram = await this.listRiwayatPrograms().then((res) => res?.[0] || {})
          this.isChooseNutritionist = !latestProgram?.nutritionistId || latestProgram?.isChooseNutritionist === 1
          this.latestProgramId = latestProgram?.id || ''
        }

      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
        this.$router.push({ name: 'client.index' })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
#table {
    width: 100%;
}
#table::v-deep tr,
#table::v-deep td{
    padding-bottom: 10px;
}

@media (min-width: 768px) {
    #table::v-deep tr,
    #table::v-deep td{
        padding-bottom: 13px;
    }
}
</style>
