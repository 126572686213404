var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem']
    }
  }, [_c('Stepper', {
    attrs: {
      "margin-top": "16px",
      "margin-bottom": ['12px', '20px'],
      "steps": [{
        label: 'Informasi Pribadi'
      }, {
        label: 'Periksa'
      }, {
        label: 'Pembayaran',
        isCurrentStep: true,
        isError: _vm.isTransactionFailed
      }]
    }
  }), !_vm.isLoading && _vm.transactionStatus === 'failed' ? _c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "28px",
      "text-align": "center"
    }
  }, [_vm._v(" Pembayaran Gagal! ")]), _c('c-box', {
    attrs: {
      "max-height": ['196px', '226px'],
      "height": "100%",
      "max-width": ['173px', '200px'],
      "width": "100%",
      "margin-top": "20px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/images/illustration-6-raw.png'),
      "height": "100%",
      "width": "100%"
    }
  })], 1), _c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "margin-top": "20px",
      "text-align": "center"
    }
  }, [_vm._v(" Ups! pembayaran kamu gagal, pesanan otomatis dibatalkan, beli ulang pesananmu ya. ")]), _c('c-box', {
    attrs: {
      "w": "100%",
      "display": "flex",
      "justify-content": "center",
      "margin-top": "20px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "variant": "contained",
      "width": "100%",
      "border-radius": "1000px",
      "max-width": ['269px', '500px']
    },
    on: {
      "click": function click($event) {
        return _vm.handleNext('visitTransactionHistory');
      }
    }
  }, [_vm._v(" Cek Riwayat Transaksi ")])], 1)], 1) : !_vm.isLoading && _vm.transactionStatus === 'done' ? _c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "28px",
      "text-align": "center"
    }
  }, [_vm._v(" Pembayaran Berhasil! ")]), _c('c-box', {
    attrs: {
      "max-height": ['196px', '226px'],
      "height": "100%",
      "max-width": ['173px', '200px'],
      "width": "100%",
      "margin-top": "20px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/images/image-payment-success.svg'),
      "height": "100%",
      "width": "100%"
    }
  })], 1), _c('c-box', {
    attrs: {
      "background-color": ['#FFF', '#FFF'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['8px', '16px'],
      "padding": ['12px', '30px'],
      "margin-top": "20px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "20px",
      "color": "primary.400"
    }
  }, [_vm._v(" Hi " + _vm._s(_vm.fullName) + ", ")]), _c('BaseDivider'), _c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "16px-2"
    }
  }, [_vm._v(" Pembayaran untuk pesanan dengan nomor " + _vm._s(_vm.orderId) + " sudah kami terima, berikut detail pembayaranmu: ")]), _c('c-box', {
    attrs: {
      "id": "table",
      "as": "table",
      "margin-top": ['12px', '25px'],
      "border-spacing": ['160px']
    }
  }, [_c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "white-space": "nowrap",
      "margin-right": "16px"
    }
  }, [_vm._v(" Nama Program ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" : " + _vm._s(_vm.programName) + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "white-space": "nowrap",
      "margin-right": "16px"
    }
  }, [_vm._v(" Biaya ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" : " + _vm._s(_vm.price) + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "white-space": "nowrap",
      "margin-right": "16px"
    }
  }, [_vm._v(" Metode Pembayaran ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" : " + _vm._s(_vm.paymentMethod) + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "white-space": "nowrap",
      "margin-right": "16px"
    }
  }, [_vm._v(" Tanggal ")])], 1), _c('c-box', {
    attrs: {
      "as": "td"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" : " + _vm._s(_vm.orderDate) + " ")])], 1)], 1)], 1)], 1), _c('BaseText', {
    attrs: {
      "size-mobile": "14px",
      "size-desktop": "20px",
      "color": "primary.400",
      "margin-top": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.isChooseNutritionist ? 'Silahkan melakukan pemilihan ahli gizi' : 'Durasi program akan otomatis diperpanjang dengan program yang sebelumnya') + " ")]), _c('c-box', {
    attrs: {
      "w": "100%",
      "display": "flex",
      "justify-content": "center",
      "margin-top": "20px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "variant": "contained",
      "width": "100%",
      "border-radius": "1000px",
      "max-width": ['269px', '500px']
    },
    on: {
      "click": _vm.handleNext
    }
  }, [_vm._v(" " + _vm._s(_vm.isChooseNutritionist ? 'Pilih Ahli Gizi' : 'Cek Program') + " ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }